<template>
  <div>
    <Editor :url="url" :fields="fields" :filters="filters" icon="mdi-account-group"
            testo-list="Lista utenti"
            testo-insert="Nuovo utente"
            testo-update="Modifica utente"
            testo-delete="Vuoi cancellare l'utente?">
      <template #form="{instance, errors}">
        <v-text-field :error-messages="errors.email" v-model="instance.email"
                      label="E-Mail" type="email"></v-text-field>
        <v-text-field :error-messages="errors.first_name" v-model="instance.first_name"
                      label="Nome"></v-text-field>
        <v-text-field :error-messages="errors.last_name" v-model="instance.last_name"
                      label="Cognome"></v-text-field>
        <v-text-field :error-messages="errors.indirizzo" v-model="instance.indirizzo"
                      label="Indirizzo"></v-text-field>
        <v-text-field :error-messages="errors.comune" v-model="instance.comune"
                      label="Comune"></v-text-field>
        <v-text-field :error-messages="errors.telefono" v-model="instance.telefono"
                      label="Telefono"></v-text-field>
        <v-text-field :error-messages="errors.cellulare" v-model="instance.cellulare"
                      label="Cellulare"></v-text-field>
        <v-text-field :error-messages="errors.rating" v-model="instance.rating"
                      label="Rating"></v-text-field>
        <v-text-field :error-messages="errors.telegram_login" v-model="instance.telegram_login"
                      label="Telegram Login"></v-text-field>
        <v-select :items="ruoli" :error-messages="errors.ruolo" v-model="instance.ruolo"
                  label="Ruolo"></v-select>
        <Relation v-model="instance.regioni" url="/backend/autocomplete/regione"
                  label="Regioni" multiple></Relation>
        <Relation v-model="instance.gruppi" url="/backend/autocomplete/gruppo"
                  label="Gruppi" multiple></Relation>
      </template>
      <template #actions="{item}">
        <Confirm :message="`Cambio password: ${item.full_name}`" @confirm="updatePassword(item)">
          <template #activator="{on, attrs}">
            <v-btn v-on="on" :attrs="attrs" color="lime" small class="mx-2">
              <v-icon small>mdi-lock</v-icon>
            </v-btn>
          </template>
          <template #default>
            <v-text-field type="password" v-model="newPassword"></v-text-field>
          </template>
        </Confirm>
      </template>
    </Editor>
  </div>
</template>


<script>
import Editor from "@/components/Editor";
import Relation from "@/components/Relation";
import {mapState} from "vuex";
import Confirm from "@/components/Confirm";

export default {
  data: () => ({
    url: '/backend/users/nopa/',
    fields: [
      {text: 'E-Mail', value: 'email'},
      {text: 'Nome', value: 'first_name'},
      {text: 'Cognome', value: 'last_name'},
      {text: 'Telefono', value: 'telefono'},
      {text: 'Cellulare', value: 'cellulare'},
      {text: 'Ruolo', value: 'ruolo'},
      {text: 'Attivo', value: 'is_active'},
    ],
    newPassword: '',
  }),
  computed: {
    ...mapState(['dataset']),
    filters() {
      return [
        {field: 'email', label: 'E-Mail'},
        {label: 'Nome', field: 'first_name'},
        {label: 'Cognome', field: 'last_name'},
        {label: 'Ruolo', field: 'ruolo', options: this.ruoli},
      ];
    },
    ruoli() {
      return this.dataset['user_profile_ruolo'];
    },
  },
  methods: {
    async updatePassword(item) {
      const data = {password: this.newPassword};
      const res = await this.$http.post(`${this.url}${item.id}/change_password/`, data);
      if(res.ok)
        this.notify('Password modificata');
    }
  },
  components: {
    Relation,
    Editor,
    Confirm,
  },
}
</script>